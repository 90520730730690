export const blogContent1 = [
  {
    image: require("../../images/blog/grid/pic1.jpg"),
    title:
      "Our dynamic and youthful approach allows us to tackle modern engineering challenges effectively.",
    description:
      "Our Fabrication Workshop stands as a testament to precision engineering, innovation, and the art of crafting metal into functional masterpieces. From conceptualization to realization, we pride ourselves on transforming ideas into tangible, high-quality products.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic2.jpg"),
    title: "Electrical And Instrumentation",
    description:
      "Welcome to Industrial Design Equipment Control (Pvt) Ltd, where excellence meets precision in the realm of Electrical and Instrumentation solutions. As a leader in the industry, we specialize in providing comprehensive services that drive efficiency, reliability, and innovation across diverse sectors.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic3.jpg"),
    title: "Machinery Installation & Maintenance",
    description:
      "At Industrial Design Equipment Control (Pvt) Ltd, we specialize in the expert installation and maintenance of industrial machinery, ensuring optimal performance and longevity. With a commitment to precision and efficiency, our dedicated team brings reliability to every aspect of your machinery operations.",
    author: "Jack",
  },
];

export const blogContent2 = [
  {
    image: require("../../images/our-services/ship/pic1.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-services/ship/pic1.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-services/ship/pic2.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-services/ship/pic3.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-services/ship/pic2.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
];

export const blogContent3 = [
  {
    image: require("../../images/blog/grid/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/blog/grid/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
];

export const blogContent4 = [
  {
    image: require("../../images/our-work/leather/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/leather/pic1.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/leather/pic2.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/leather/pic3.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/leather/pic2.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
];

export const blogContent5 = [
  {
    image: require("../../images/our-work/nuclear/pic1.jpg"),
    title: "Why You Should Not Go To Industry",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/nuclear/pic2.jpg"),
    title: "Seven Doubts You Should Clarify About",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/nuclear/pic3.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/nuclear/pic4.jpg"),
    title: "Seven Doubts You Should Clarify About",
    author: "Jack",
  },
];

export const blogContent6 = [
  {
    image: require("../../images/our-work/beer/pic1.jpg"),
    title: "Why You Should Not Go To Industry",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/beer/pic2.jpg"),
    title: "Seven Doubts You Should Clarify About",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/beer/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/beer/pic2.jpg"),
    title: "Seven Doubts You Should Clarify About",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
];

export const blogContent7 = [
  {
    image: require("../../images/our-work/car/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/car/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/car/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/car/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/car/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/car/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
];

export const blogContent8 = [
  {
    image: require("../../images/our-work/mining/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/mining/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/mining/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/mining/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/mining/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/mining/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
];

export const blogContent9 = [
  {
    image: require("../../images/our-work/plastic/pic1.jpg"),
    title: "Why You Should Not Go To Industry",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/plastic/pic2.jpg"),
    title: "Seven Doubts You Should Clarify About",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/plastic/pic3.jpg"),
    title: "Seven Doubts You Should",
    author: "Jack",
  },
  {
    image: require("../../images/our-work/plastic/pic4.jpg"),
    title: "Seven Doubts You Should Clarify About",
    author: "Jack",
  },
];

export const ExibitionContent1 = [
  {
    image: require("./../../images/gallery/food/pic1.jpg"),
  },
  {
    image: require("./../../images/gallery/food/pic2.jpg"),
  },
  {
    image: require("./../../images/gallery/food/pic3.jpg"),
  },
  {
    image: require("./../../images/gallery/food/pic4.jpg"),
  },
];

export const exibitionContent2 = [
  {
    image: require("./../../images/gallery/leather/pic1.jpg"),
  },
  {
    image: require("./../../images/gallery/leather/pic2.jpg"),
  },
  {
    image: require("./../../images/gallery/leather/pic3.jpg"),
  },
  {
    image: require("./../../images/gallery/leather/pic4.jpg"),
  },
];

export const projectContent1 = [
  {
    image: require("../../images/our-work/steelplant/pic10.jpg"),
    projectName: "Manufacturing",
  },
  {
    image: require("../../images/our-work/steelplant/pic9.jpg"),
    projectName: "Iron Making",
  },
  {
    image: require("../../images/our-work/steelplant/pic8.jpg"),
    projectName: "Steel Pipes",
  },
  {
    image: require("../../images/our-work/steelplant/pic7.jpg"),
    projectName: "Structural Steel",
  },
  {
    image: require("../../images/our-work/steelplant/pic10.jpg"),
    projectName: "Manufacturing",
  },
];

export const projectContent2 = [
  {
    image: require("../../images/our-work/solarplant/pic1.jpg"),
    projectName: "Manufacturing",
  },
  {
    image: require("../../images/our-work/solarplant/pic2.jpg"),
    projectName: "Iron Making",
  },
  {
    image: require("../../images/our-work/solarplant/pic3.jpg"),
    projectName: "Steel Pipes",
  },
  {
    image: require("../../images/our-work/solarplant/pic4.jpg"),
    projectName: "Structural Steel",
  },
  {
    image: require("../../images/our-work/solarplant/pic3.jpg"),
    projectName: "Steel Pipes",
  },
  {
    image: require("../../images/our-work/solarplant/pic4.jpg"),
    projectName: "Structural Steel",
  },
];

export const servicesContent1 = [
  {
    serviceName: "Wind Turbines",
    icon: "flaticon-worker",
  },
  {
    serviceName: "Solar Panels",
    icon: "flaticon-factory",
  },
  {
    serviceName: "Maintenance",
    icon: "flaticon-settings",
  },
  {
    serviceName: "Maintenance",
    icon: "flaticon-engineer-1",
  },
];

export const serviceContent2 = [
  {
    serviceName: "Material Engineering",
    icon: "flaticon-worker",
  },
  {
    serviceName: "Power and Energy",
    icon: "flaticon-factory",
  },
  {
    serviceName: "Agricultural Engineering",
    icon: "flaticon-settings",
  },
  {
    serviceName: "Petroleum Engineering",
    icon: "flaticon-engineer-1",
  },
  {
    serviceName: "Material Engineering",
    icon: "flaticon-worker",
  },
];

export const servicesContent3 = [
  {
    image: require("../../images/our-work/solarplant/pic1.jpg"),
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    serviceName: "Wind Turbines",
  },
  {
    image: require("../../images/our-work/solarplant/pic2.jpg"),
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    serviceName: "Solar Panels",
  },
  {
    image: require("../../images/our-work/solarplant/pic3.jpg"),
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    serviceName: "Maintenance",
  },
  {
    image: require("../../images/our-work/solarplant/pic2.jpg"),
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    serviceName: "Solar Panels",
  },
  {
    image: require("../../images/our-work/solarplant/pic3.jpg"),
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    serviceName: "Maintenance",
  },
];

export const servicesContent4 = [
  {
    image: require("../../images/our-services/pic1.jpg"),
    description:
      "We are at the cutting edge of technological advancements in the industry.",
    serviceName: "Innovation",
  },

  {
    image: require("../../images/our-services/pic3.jpg"),
    description:
      "Strategic alliances with leading global suppliers ensure top-notch equipment and technology.",
    serviceName: "Global Partnerships",
  },
  {
    image: require("../../images/our-services/pic2.jpg"),
    description:
      "Our dynamic and youthful approach allows us to tackle modern engineering challenges effectively.",
    serviceName: "Youthful Dynamism",
  },
];
export const homeSliderContent2 = [
  {
    image: require("../../images/main-slider/slide4.jpg"),
    title: "GAS INDUSTRY",
    description:
      "Industry is ready to help you in making unique-looking and best website in a moment.",
  },
  {
    image: require("../../images/main-slider/slide5.jpg"),
    title: "OIL INDUSTRY",
    description:
      "Industry is ready to help you in making unique-looking and best website in a moment.",
  },
];

export const homeSliderContent3 = [
  {
    image: require("../../images/main-slider/slide21.jpg"),
    title: "Economy Needs A Healthy Steel Industry",
  },
  {
    image: require("../../images/main-slider/slide22.jpg"),
    title: "Economy Needs A Healthy Steel Industry",
  },
];

export const homeSliderContent4 = [
  {
    image: require("../../images/main-slider/slide1.jpg"),
    title: "Fast and Reliable Electrical services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require("../../images/main-slider/slide2.jpg"),
    title: "Fast and Reliable Electrical services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

export const homeSliderContent5 = [
  {
    image: require("../../images/main-slider/slide9.jpg"),
    title: "WE BUILD YOUR DREAM",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require("../../images/main-slider/slide10.jpg"),
    title: "WE ARE CONSTRUCT",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

export const homeSliderContent6 = [
  {
    image: require("../../images/main-slider/slide11.jpg"),
    title: "Solar Wind Power Technology ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require("../../images/main-slider/slide12.jpg"),
    title: "Solar Wind Power Technology ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

export const homeSliderContent7 = [
  {
    subTitle: "Our Latest Item Food Industry",
    image: require("../../images/main-slider/slide13.jpg"),
    title: "Our Latest Item Food Industry ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    subTitle: "Our Latest Item Food Industry",
    image: require("../../images/main-slider/slide14.jpg"),
    title: "Our Latest Item Food Industry ",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

export const homeSliderContent8 = [
  {
    subTitle: "INVESTMENT OPPORTUNITIES IN LEATHER",
    image: require("../../images/main-slider/slide25.jpg"),
    title: "Welcome Leather Industry",
  },
  {
    subTitle: "INVESTMENT OPPORTUNITIES IN LEATHER",
    image: require("../../images/main-slider/slide24.jpg"),
    title: "Welcome Leather Industry",
  },
];

export const homeSliderContent9 = [
  {
    subTitle: "INVESTMENT OPPORTUNITIES IN LEATHER",
    image: require("../../images/main-slider/slide23.jpg"),
    title: "Nuclear Power Plants",
    description: "Great Lessons You Can Learn From Nuclear Plant",
  },
];

export const homeSliderContent10 = [
  {
    subTitle2: "Sale Car – Buy Car – Car Service",
    image: require("../../images/main-slider/slide29.jpg"),
    title: "Auto Mobile Industry",
    description:
      " With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play.",
  },
];

export const homeSliderContent11 = [
  {
    subTitle: "Value Creation Form Waste ",
    image: require("../../images/main-slider/slide19.jpg"),
    title: "Plastic Waste Managment & Recycling",
    description: "Powering Progress Through Plastic",
  },
];
