import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
//import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content = [
  {
    review:
      "IDEC's commitment to cutting-edge technology and efficient services truly sets them apart — a reliable partner in our industrial journey.",
    image: require("./../../images/testimonials/pic1.jpg"),
    author: "Alexer Valvin",
    designation: "Student",
  },
  {
    review:
      "Choosing IDEC was a game-changer for our operations; their dedication to excellence is evident in every service they provide.",
    image: require("./../../images/testimonials/pic2.jpg"),
    author: "Alexer Valvin",
    designation: "Student",
  },
  {
    review:
      "IDEC's expertise in fabrication, installation, and comprehensive solutions makes them our trusted ally in navigating complex industrial challenges.",
    image: require("./../../images/testimonials/pic3.jpg"),
    author: "Alexer Valvin",
    designation: "Student",
  },
  //   {
  //     image: require("./../../images/testimonials/pic1.jpg"),
  //     author: "Alexer Valvin",
  //     designation: "Student",
  //   },
  // {
  //     image: require('./../../images/testimonials/pic1.jpg'),
  //     author: 'Alexer Valvin',
  //     designation: 'Student',
  // },
  // {
  //     image: require('./../../images/testimonials/pic1.jpg'),
  //     author: 'Alexer Valvin',
  //     designation: 'Student',
  // },
];

class Testimonial1 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      dotsClass: "slick-dots slick-thumb customCls",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider
          {...settings}
          className="testimonial-two-dots btn-style-1 btn-white dots-long d-primary"
        >
          {content.map((item, id) => (
            <div className="item">
              <div className="testimonial-15 text-white">
                <div className="testimonial-text quote-left quote-right">
                  <p>{item.review}</p>
                </div>
                <div className="testimonial-detail clearfix">
                  {/* <div className="testimonial-pic radius shadow">
                    <img src={item.image} width="100" height="100" alt="" />
                  </div> */}
                  {/* <strong className="testimonial-name">{item.author}</strong>{" "} */}
                  {/* <span className="testimonial-position">
                    {item.designation}
                  </span> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default Testimonial1;
