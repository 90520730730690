import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ClientSlider1 from "../element/clientSlider1";
// import BlogSlider1 from "../element/blogSlider1";
import { blogContent3, servicesContent4 } from "../element/SliderContent";
import ServicesSlider1 from "../element/servicesSlider1";
import Testimonial12 from "../element/testimonial12";
//import HomeSlider from '../element/homeslider1'
import { Parallax } from "react-parallax";
import CounterSection from "../element/counterSection";
// import Team1 from "../element/team1";

import "../../css/plugins.css";
import "../../css/style.css";
import "../../css/skin/skin-1.css";
import "../../css/templete.min.css";
import ScrollToTop from "../element/scrollToTop";

const bg = require("../../images/background/bg2.jpg");
const bg2 = require("../../images/background/bg4.jpg");
const bg3 = require("../../images/background/map-bg.png");
const bg4 = require("../../images/main-slider/slide8.jpg");

class Index extends Component {
  render() {
    return (
      <div className="skin-1">
        <Header />

        <Parallax bgImage={bg4} strength={200}>
          <div className="hero-section pt-50 home-bnr1">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="hero-content">
                    <h1 className="title">Empowering Innovation</h1>
                    <div className="description">
                      We are a team of professional actively engaged in
                      supplying and installing industrial machinery.
                    </div>
                    <div className="buttons">
                      <Link to={"#"}>Read More</Link>
                      <Link to={"#"}>About Us</Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <img
                    src={require("../../images/main-slider/slide7.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </Parallax>

        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full bg-gray content-inner about-carousel-ser">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">
                    why Customer Love To Choose Us
                    <br />
                    <span className="text-primary">
                      Embrace Our Partnership
                    </span>
                  </h2>
                  <p>
                    Our commitment to excellence has earned us the trust of
                    businesses of all sizes.
                  </p>
                </div>

                <ServicesSlider1 slidesToShow={3} data={servicesContent4} />
              </div>
            </div>

            <div
              className="section-full bg-img-fix content-inner-2 overlay-black-dark contact-action style2"
              style={{ backgroundImage: "url(" + bg + ")" }}
            >
              <div className="container">
                <div className="row relative">
                  <div
                    className="col-md-12 col-lg-8 wow fadeInLeft"
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                  >
                    <div className="contact-no-area">
                      <h2 className="title">
                        A company involved in service, maintenance
                      </h2>
                      <div className="contact-no">
                        <div className="contact-left">
                          <h3 className="no">
                            <i className="sl-call-in"></i>+92-315-432-8961
                          </h3>
                        </div>
                        <div className="contact-right">
                          <Link
                            to="/contact-1"
                            className="site-button appointment-btn btnhover13"
                          >
                            Get Appointment
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-12 col-lg-4 contact-img-bx wow fadeInRight relative"
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                  >
                    <img src={require("../../images/pic1.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-full bg-gray content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title"> Our Services</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    typesetting industry has been the industry's standard dummy
                    text ever since the been when an unknown printer.
                  </p>
                </div>
                <div className="section-content row">
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                  >
                    <div className="icon-bx-wraper" data-name="01">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-robot-arm"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">
                          SS and MS Fabrication Workshop.
                        </h2>
                        <p>
                          Our state-of-the-art fabrication workshop specializes
                          in stainless steel (SS) and mild steel (MS), ensuring
                          precision and quality in every project.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.4s"
                  >
                    <div className="icon-bx-wraper" data-name="02">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-factory-1"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Machinery Installation.</h2>
                        <p>
                          Seamlessly integrating cutting-edge machinery, our
                          expert team ensures efficient and precise installation
                          for optimal performance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <div className="icon-bx-wraper" data-name="03">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-fuel-station"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">
                          Mechanical, Electrical and Plumbing Solutions
                        </h2>
                        <p>
                          Offering comprehensive solutions, we excel in
                          providing integrated mechanical, electrical, and
                          plumbing services tailored to your specific
                          requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.8s"
                  >
                    <div className="icon-bx-wraper" data-name="04">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-fuel-truck"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">
                          Commissioning and Startups
                        </h2>
                        <p>
                          Trust us for professional commissioning and startup
                          services, ensuring a smooth transition from
                          installation to operational efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                  >
                    <div className="icon-bx-wraper" data-name="05">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-conveyor-1"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">Utility Audits</h2>
                        <p>
                          Our thorough utility audits help optimize resource
                          utilization, identify efficiency improvements, and
                          enhance overall operational sustainability.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.4s"
                  >
                    <div className="icon-bx-wraper" data-name="06">
                      <div className="icon-lg">
                        <Link to="#" className="icon-cell">
                          <i className="flaticon-engineer-1"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h2 className="dlab-tilte">
                          Electrical And Instrumentation
                        </h2>
                        <p>
                          From precise electrical installations to sophisticated
                          instrumentation solutions, ensuring reliability and
                          efficiency in every aspect of your operations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CounterSection backgroundImage={bg2} />

            <div className="section-full bg-gray content-inner">
              <div className="container">
                {/* <div className="section-head text-center ">
                  <h2 className="title"> Meet The Team</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    typesetting industry has been the industry's standard dummy
                    text ever since the been when an unknown printer.
                  </p>
                </div>
                <Team1 /> */}
              </div>
            </div>

            <div
              className="section-full overlay-black-middle bg-secondry content-inner-2 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
              style={{ backgroundImage: "url(" + bg3 + ")" }}
            >
              <div className="container">
                <div className="section-head text-white text-center">
                  <h2 className="title">What People Are Saying</h2>
                  <p>Empowering Innovation Through Engineering Excellence.</p>
                </div>
                <div className="section-content">
                  <Testimonial12 />
                </div>
              </div>
            </div>

            <div
              className="section-full content-inner bg-gray wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.4s"
            >
              {/* <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">Latest blog post</h2>
                  <p></p>
                </div>

                <BlogSlider1
                  data={blogContent3}
                  blogPostCls="post-style-1"
                  imgEffect="rotate"
                  noDes
                />
              </div> */}
            </div>

            <div
              className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-content">
                  <div className="section-head text-center">
                    <h2 className="title">Our Clients</h2>
                  </div>
                  <ClientSlider1 hideBorder />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo displayNewsLetter />
        <ScrollToTop className="icon-up" />
      </div>
    );
  }
}
export default Index;
