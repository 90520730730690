import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

class Header extends Component {
  componentDidMount() {
    var searchBtn = document.getElementById("quik-search-btn");
    var searchPopup = document.querySelector(".dlab-quik-search");
    var closeBtn = document.getElementById("quik-search-remove");

    searchBtn.addEventListener("click", function () {
      searchPopup.style.display = "block";
      searchPopup.classList.add("On");
    });

    closeBtn.addEventListener("click", function () {
      searchPopup.style.display = "none";
      searchPopup.classList.remove("On");
    });

    // sidebar open/close

    var btn = document.querySelector(".navicon");
    var nav = document.querySelector(".header-nav");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open");
        //console.log("active")
      } else {
        current.classList.add("open");
        //console.log("close")
      }
    }
  }
  render() {
    return (
      <>
        <header className="site-header mo-left header">
          <div className="top-bar">
            <div className="container">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="dlab-topbar-left">
                  <ul>
                    <li>
                      <Link to={"/about"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/#"} className="ms-1">
                        Refund Policy
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#"} className="ms-1">
                        Help Desk
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="dlab-topbar-right">
                  <Link to={"/contact"} className="site-button radius-no">
                    GET A QUOTE
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <Sticky innerZ={999} enabled={true}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
              <div className="main-bar clearfix ">
                <div className="container clearfix">
                  <div className="logo-header mostion">
                    <Link to={"/"}>
                      <img
                        src={require("../../images/ideclogo/idec-logo.jpeg")}
                        alt=""
                      />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div className="extra-nav">
                    <div className="extra-cell">
                      <button
                        id="quik-search-btn"
                        type="button"
                        className="site-button-link"
                      >
                        <i className="la la-search"></i>
                      </button>
                    </div>
                  </div>

                  <div className="dlab-quik-search ">
                    <form action="#">
                      <input
                        name="search"
                        value=""
                        type="text"
                        className="form-control"
                        placeholder="Type to search"
                      />
                      <span id="quik-search-remove">
                        <i className="ti-close"></i>
                      </span>
                    </form>
                  </div>

                  <div
                    class={`header-nav navbar-collapse collapse justify-content-end`}
                    id="navbarNavDropdown"
                  >
                    <div className="logo-header d-md-block d-lg-none">
                      <Link to={"/"}>
                        <img
                          src={require("../../images/ideclogo/idec-logo.jpeg")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <ul className="nav navbar-nav">
                      <li className="active has-mega-menu homedemo">
                        {" "}
                        <Link to={"/"}>
                          Home
                          <i
                          //   className="fa fa-chevron-down"
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={"#"}>
                          Company<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu right">
                          <li>
                            <Link to={"/about"}>About Us</Link>
                          </li>
                          <li>
                            <Link to={"/"}>Mission Statement</Link>
                          </li>
                          <li>
                            <Link to={"/"}>CEO Message</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to={"#"}>
                          Products<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu right">
                          <li>
                            <Link to={"/processfiltration"}>
                              {" "}
                              Proccess Filtration
                            </Link>
                          </li>

                          <li>
                            <Link to={"/vacuumpumps"}> Vacuum Pumps</Link>
                          </li>

                          <li>
                            <Link to={"/instrumental-shop"}>
                              Instrumentation & Control System
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to={"#"}>
                          Services<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu right">
                          <li>
                            <Link to={"/"}> Mechanical Works</Link>
                          </li>
                          <li>
                            <Link to={"/"}>
                              {" "}
                              Electrical And Instrumentation
                            </Link>
                          </li>
                          <li>
                            <Link to={"/"}>
                              {" "}
                              Machinery Installation & Maintenance
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to={"#"}>
                          Principles <i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu right">
                          <li>
                            <a
                              href="https://www.donaldson.com/en-be/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Donaldson
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.gucumpompa.com/en/home"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Gucum
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to={"/contact"}>
                          Contact Us<i></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="dlab-social-icon">
                      <ul>
                        <li>
                          <Link
                            className="site-button circle fa fa-facebook"
                            to={"/#"}
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button  circle fa fa-twitter"
                            to={"/#"}
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button circle fa fa-linkedin"
                            to={"/#"}
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button circle fa fa-instagram"
                            to={"/#"}
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}
export default Header;
