import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const bg = require("../../images/banner/bnr4.jpg");

const Rating = () => {
  return (
    <>
      <ul className="item-review">
        <li>
          <i className="fa fa-star me-1"></i>
        </li>
        <li>
          <i className="fa fa-star me-1"></i>
        </li>
        <li>
          <i className="fa fa-star me-1"></i>
        </li>
        <li>
          <i className="fa fa-star-half-o me-1"></i>
        </li>
        <li>
          <i className="fa fa-star-o"></i>
        </li>
      </ul>
    </>
  );
};

class InstrumentalShop extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bg + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Flow Meters</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Flow Meters</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/Vortex Flow Meters.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>Electromagnetic Flow Meters</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10 item-style-1">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/Ultrasonic flow Meters.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>Vortex Flow Meters</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/Ultrasonic flow Meters org.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>Ultrasonic flow Meters</a>
                      </h6>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/Calorimeter.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>Calorimeter</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/CORIOLIS FLOWMETER.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>Coriolis Flowmeter</a>
                      </h6>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/AU-20 Level Controller with 2 Contacts.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>AU-20 Level Controller with 2 Contacts</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/AU-21 Level Controler with 2 Contacts.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>AU-21 Level Controler with 2 Contacts</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/AU-22 Level Control with 3 Contacts.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>AU-22 Level Control with 3 Contacts</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/EG-11 Analog Level Controller.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>EG-11 Analog Level Controller</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/BT-01 Hydrostatic Level Transmitter.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>BT-01 Hydrostatic Level Transmitter</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/Pressure Transmitter.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a rel="noopener noreferrer">Pressure Transmitter</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="item-box m-b10">
                    <div className="item-img">
                      <img
                        src={require("../../images/idecimages/AECAP Capacitive Level Transmitter.png")}
                        alt=""
                      />
                      <div className="item-info-in">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="ti-eye"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="ti-heart"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="item-info text-center text-black p-a10">
                      <h6 className="item-title font-weight-500">
                        <a>AECAP Capacitive Level Transmitter</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full p-t50 p-b20 bg-primary text-white">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="icon-bx-wraper left shop-service-info m-b30">
                    <div className="icon-md text-black radius">
                      <Link to="#" className="icon-cell text-white">
                        <i className="fa fa-gift"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        Free shipping on orders $6000+
                      </h5>
                      <p>
                        Order more than 6000$ and you will get free shippining
                        Worldwide. More info.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="icon-bx-wraper left shop-service-info m-b30">
                    <div className="icon-md text-black radius">
                      <Link to="#" className="icon-cell text-white">
                        <i className="fa fa-plane"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte">Worldwide delivery</h5>
                      <p>
                        We deliver to the following countries: Pakistan, USA,
                        Canada, Europe, Australia
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="icon-bx-wraper left shop-service-info m-b30">
                    <div className="icon-md text-black radius">
                      <Link to="#" className="icon-cell text-white">
                        <i className="fa fa-history"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        60 days money back guranty!
                      </h5>
                      <p>
                        Not happy with our product, feel free to return it, we
                        will refund 100% your money!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </>
    );
  }
}
export default InstrumentalShop;
